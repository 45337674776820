<template>
  <v-container
    class="pa-6 d-flex flex-column justify-center align-center"
    style="min-height: 100vh"
  >
    <div
      class="d-flex justify-center align-center"
    >
      <v-btn
        class="primary  mt-4 lighten-2 text-h2"
        elevation="0"
        height="120px"
        style="border-radius: 60px"
        width="360px"
        @click="printDialog = true"
      >
        打印
      </v-btn>
    </div>
    <v-dialog
      v-model="printDialog"
      max-width="1200px"
    >
      <v-card>
        <div class="d-flex align-center pa-4">
          <div class="text-h3 font-weight-bold">
            选择打印内容
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="printDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <template v-if="isLoading">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                :editable="step > 1"
                :step="1"
              >
                选择分类
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step
                :complete="step > 2"
                :editable="step > 2"
                :step="2"
              >
                选择菜品
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step
                :complete="step > 3"
                :editable="step > 3"
                :step="3"
              >
                选择配料
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step
                :complete="step > 4"
                :editable="step > 4"
                :step="4"
              >
                选择SKU
              </v-stepper-step>
              <v-divider/>

              <v-stepper-step :step="5">
                确认信息
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-item-group
                  v-model="categorySelect"
                  mandatory
                >
                  <div
                    class="mt-4"
                    style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
                  >
                    <v-item
                      v-for="i in attrCategoryList"
                      :key="i.id"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        :color="i.color"
                        class="d-flex align-center justify-center"
                        elevation="0"
                        outlined
                        @click="toggle();selectCategory(i)"
                      >
                        <v-responsive
                          :aspect-ratio="1"
                        >
                          <div
                            class="d-flex align-center justify-center flex-column pa-2"
                            style="height: 100%;position: relative"
                          >
                            <v-icon
                              v-if="active"
                              color="primary"
                              size="36"
                              style="position: absolute;bottom: 0px;"
                            >
                              mdi-check-circle
                            </v-icon>
                            <div class="text-center font-weight-bold">
                              {{ i._langsname }}
                            </div>
                          </div>
                        </v-responsive>
                      </v-card>
                    </v-item>
                  </div>
                </v-item-group>
              </v-stepper-content>

              <v-stepper-content step="2">
                <template v-if="isLoading">
                  <v-item-group
                    v-model="dishSelect"
                    mandatory
                  >
                    <div
                      class="mt-4"
                      style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
                    >
                      <v-item
                        v-for="i in dishList"
                        :key="i.id"
                        v-slot="{ active, toggle }"
                      >
                        <v-card
                          class="d-flex align-center justify-center"
                          elevation="0"
                          outlined
                          @click="toggle();selectDish(i)"
                        >
                          <v-responsive
                            :aspect-ratio="1"
                          >
                            <div
                              class="d-flex align-center justify-center flex-column pa-2"
                              style="height: 100%;position: relative"
                            >
                              <v-icon
                                v-if="active"
                                color="primary"
                                size="36"
                                style="position: absolute;bottom: 0px;"
                              >
                                mdi-check-circle
                              </v-icon>
                              <div class="text-center font-weight-bold">
                                {{ i.name }}
                              </div>
                            </div>
                          </v-responsive>
                        </v-card>
                      </v-item>
                    </div>
                  </v-item-group>
                </template>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-item-group
                  v-model="attrSelect"
                  multiple
                >
                  <div
                    class="mt-4"
                    style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
                  >
                    <v-item
                      v-for="i in attrList"
                      :key="i.id"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        class="d-flex align-center justify-center"
                        elevation="0"
                        outlined
                        @click="toggle()"
                      >
                        <v-responsive
                          :aspect-ratio="1"
                        >
                          <div
                            class="d-flex align-center justify-center flex-column pa-2"
                            style="height: 100%;position: relative"
                          >
                            <v-icon
                              v-if="active"
                              color="primary"
                              size="36"
                              style="position: absolute;bottom: 0px;"
                            >
                              mdi-check-circle
                            </v-icon>
                            <div class="text-center font-weight-bold">
                              {{ i.name }}
                            </div>
                          </div>
                        </v-responsive>
                      </v-card>
                    </v-item>
                  </div>
                </v-item-group>
              </v-stepper-content>

              <v-stepper-content step="4">
                <div>已选择配料： {{ attrSelect?.length }} 种</div>
                <v-item-group
                  v-model="SKUSelect"
                  mandatory
                >
                  <div
                    class="mt-4"
                    style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
                  >
                    <v-item
                      v-for="i in SKUList"
                      :key="i.id"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        class="d-flex align-center justify-center"
                        elevation="0"
                        outlined
                        @click="toggle();selectSKU(i)"
                      >
                        <v-responsive
                          :aspect-ratio="1"
                        >
                          <div
                            class="d-flex align-center justify-center flex-column pa-2"
                            style="height: 100%;position: relative"
                          >
                            <v-icon
                              v-if="active"
                              color="primary"
                              size="36"
                              style="position: absolute;bottom: 0px;"
                            >
                              mdi-check-circle
                            </v-icon>
                            <div class="text-center font-weight-bold">
                              {{ i.name }}
                            </div>
                          </div>
                        </v-responsive>
                      </v-card>
                    </v-item>
                  </div>
                </v-item-group>
              </v-stepper-content>

              <v-stepper-content step="5">
                <div class="text-h3">
                  <div class="d-flex">
                    <div>SKU:</div>
                    <v-spacer/>
                    <div>{{ selectedSKU?.name }}</div>
                    <v-btn icon>
                      <v-icon @click="step = 1">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex">
                    <div>分类:</div>
                    <v-spacer/>
                    <div>{{ selectedCategory?._langsname }}</div>
                    <v-btn icon>
                      <v-icon @click="step = 2">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex">
                    <div>菜品:</div>
                    <v-spacer/>
                    <div>{{ selectedDish?.name }}</div>
                    <v-btn icon>
                      <v-icon @click="step = 3">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex">
                    <div>配料:</div>
                    <v-spacer/>
                    <div>{{ attrSelectName }}</div>
                    <v-btn icon>
                      <v-icon @click="step = 4">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
            <div class="d-flex pa-4">
              <v-spacer/>
              <template v-if="step === 3">
                <v-btn
                  class="primary  mt-4 lighten-2"
                  elevation="0"
                  style="border-radius: 36px"
                  @click="nextStep"
                >
                  下一步
                </v-btn>
              </template>
              <template v-else-if="step === 5">
                <v-btn
                  class="primary  mt-4 lighten-2"
                  elevation="0"
                  style="border-radius: 36px"
                  @click="save"
                >
                  保存
                </v-btn>
              </template>
            </div>
          </v-stepper>
        </template>
        <template v-else>
          <div
            class="d-flex align-center justify-center"
            style="height: 400px"
          >
            <v-progress-circular
              indeterminate
              size="64"
            />
          </div>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { getHasIngredientAttrList, getHasSKUIngredientDishList, getPrint, getSKUList } from '@/common/Utlis/api'
  import { today } from 'metaflow-js/EntityFrameWork/DateRepository'
  import category from '@/model/dish/Category'
  import { showSuccessMessage } from '@/common/utils'

  const distinct = (a, b) => {
    if (!a.includes(b)) {
      return a.concat(b)
    }
    return a
  }

  export default {
    name: 'LabelPrint',
    data: function () {
      return {
        isLoading: false,
        selectedCategory: null,
        categorySelect: null,
        attrCategoryList: [],
        selectedSKU: null,
        selectedDish: null,
        selectedAttr: [],
        SKUSelect: null,
        dishSelect: null,
        attrSelect: null,
        printDialog: false,
        attrSelectName: [],
        attrSelectID: [],
        step: 1,
        dishList: [],
        SKUList: null,
        attrList: null,
      }
    },
    watch: {
      printDialog (val) {
        if (!val) {
          this.SKUSelect = ''
          this.dishSelect = ''
          this.attrSelect = ''
          this.categorySelect = ''
          this.step = 1
        }
      },
    },
    mounted () {
      this.reload()
    },
    methods: {
      selectSKU (item) {
        this.selectedSKU = item
        this.step = 5
      },
      async selectDish (item) {
        this.selectedDish = item
        this.SKUList = (await getSKUList({
          dishIds: item.id,
        }))
        this.step = 3
      },
      selectCategory (item) {
        this.selectedCategory = item
        console.log(item, 'item')
        this.dishList = this.dishList.filter(it => parseInt(it.dishesCategoryId) === item.id)
        console.log(this.dishList, 'list')
        this.step = 2
      },
      async save () {
        await getPrint({
          dishId: this.selectedDish.id,
          atrIds: this.attrSelectID,
          labelSkuId: this.selectedSKU.id,
        }, {
          packingDate: today,
        })
        showSuccessMessage('打印成功')
        await this.reload()
      },
      nextStep () {
        for (const item of this.attrSelect) {
          this.selectedAttr.push(this.attrList[item])
        }
        this.attrSelectName = this.selectedAttr
          .map(it => it.name)
          .reduce(distinct, [])
          .join()
        this.attrSelectID = this.selectedAttr
          .map(it => it.id)
          .reduce(distinct, [])
          .join()
        this.step = 4
      },
      async reload () {
        this.isLoading = false
        this.SKUList = await getSKUList()
        this.dishList = (await getHasSKUIngredientDishList())
        const res = this.dishList.map(it => it.dishesCategoryId).reduce(distinct, [])
        this.attrList = (await getHasIngredientAttrList())
        const categoryList = (await category.getList(true))
        res.forEach(it => {
          categoryList.forEach(x => {
            if (parseInt(it) === x.id) {
              this.attrCategoryList.push(x)
            }
          })
        })
        this.isLoading = true
      },
    },
  }
</script>

<style scoped>

</style>
